'<template>
	<div class="aboutContainer">
		<div class="banner"></div>
		<div class="content_container">
			<div class="breadcrumbs">
			<router-link to="/">首页</router-link>&lt;<router-link to="/joinus">加入我们</router-link>&lt;<a href="javascript:void()">{{articleInfo.aTitle}}</a>
			</div>
			<h1>{{articleInfo.aTitle}}</h1>
			<div class="articleInfo">
				<span class="author">作者:{{articleInfo.aAuthor}}</span>
				<span class="createtime">发布时间:{{articleInfo.createtime}}</span>
			</div>
			<div class="articleContent" v-html="aContentHtml"></div>
		</div>
	</div>
</template>

<script>
	import {
		getSingleArticle
	} from '@/api/request'
	import {
		Base64
	} from 'js-base64'
	export default {
		name: 'newsDetail',
		
		data() {
			return {
				articleId:'',
				articleInfo:{
					aTitle:'',
					aAuthor:'',
					createtime:'',
					aContent:''
				},
				aContentHtml:''
			}
		},
		computed: {

		},
		mounted() {

		},
		created() {
			this.articleId = this.$route.query.articleId;
			this.featchData();
		},
		methods: {
			featchData(){
				getSingleArticle(this.articleId).then(resp=>{
					if(resp.data.result){
						this.articleInfo = resp.data.result;
						this.aContentHtml = Base64.decode(this.articleInfo.aContent);
					}
				})
			}
		}
	}
</script>

<style>
	.aboutContainer {
		min-height: 800px;
		background-color: #175068;
	}

	.banner {
		width: 100%;
		height: 10rem;
		background: url(../../public/images/introductionbanner.png) no-repeat;
		background-size: 100% 100%;
	}

	.breadcrumbs {
		font-size:0.7rem;
		height:1rem;
		line-height: 1rem;
		color:#FFFFFF;
	}
	.breadcrumbs a{
		font-size:0.7rem;
		transition: all 0.5s;
	}
	.breadcrumbs a:hover{
		color:#2BAEB6;
	}
	h1{
		line-height: 2rem;
		text-align: center;
		margin-top: 2rem;
		color:#FFFFFF;
		font-size: 1.2rem;
	}
	.articleInfo{
		height:1rem; line-height: 1rem;
		
	}
	.articleInfo span{
		display: block;
		font-size: 0.6rem;
		color:#999;
	}
	.articleInfo .author{
		float: right;
	}
	.articleInfo .createtime{
		float: right;
		margin-right: 1rem;
		
	}
	.articleContent{
		padding-top:1.5rem;
		color:#FFFFFF;
		margin-bottom: 2rem;
	}
</style>
